<template>
    <div class="page">
        <div class="content" v-if="$route.path == '/systemsetup/pagecontent'">
            <div style="width: 100%;background-color: #fff;padding: 20px;">
                <div style="float: left;"><Button type="primary" @click="addPage">添加页面</Button></div>
                <div style="margin-top: 50px;">
                    <Table :columns="columnsTable" :data="dataTable" border>
                        <template slot-scope="{ index }" slot="id">
                            <div>{{ index + 1 }}</div>
                        </template>
                        <template slot-scope="{ row, index }" slot="type">
                            <span class="infoModal_text" @click="editPage(row)">修改</span>
                            <span class="infoModal_text" @click="instance(row.id)">删除</span>
                        </template>
                    </Table>
                    <Page class="Page" :current="storePrams.page" :page-size="storePrams.limit" :total="total" show-elevator show-total @on-change="pageChange"/>
                </div>
            </div>
        </div>
        <router-view v-else></router-view>
    </div>
</template>

<script>
import { pageList, pageDel,} from "@/api/index";
    export default {
        data() {
            return {
                columnsTable: [
                    {title: '#',slot: "id",align:'center'},
                    {title: '页面标题',key: 'title',align:'center'},
                    {title: '时间',key: 'created_at',align:'center'},
                    {title: '操作',slot: 'type',align:'center'},
                ],
                dataTable: [
                    {title: '',created_at: ''},
                ],
                total: 0,
                storePrams: {
                    page: 1,
                    limit: 30,
                },
            }
        },
        watch: {
            $route() {     
                setTimeout(()=>{   
                    this.dataTable = []
                    this.storePrams.page = 1                 
                    this.pageList()  
                },500)               
            }
        },
        created(){
            this.pageList()
        },
        methods: {
            // 页面列表
            pageList(){                
                pageList(this.storePrams).then(res=>{
                    this.total = res.data.total
                    this.dataTable = res.data.data
                })
            },
            addPage(){             
                this.$router.push({path: '/systemsetup/pagecontent/addpage'})
            },
            editPage(e){
                this.$router.push({path: '/systemsetup/pagecontent/editpage?id='+e.id})
            },
            pageChange(index) {
                this.storePrams.page = index;
            },
            //删除操作
            instance (e) {
                this.$Modal.confirm({
                    title: '警告提示',
                    content: '<p>是否确认删除页面内容</p>',
                    onOk: () => {
                        pageDel({id:e}).then(res=>{
                            this.$Message.success(res.msg)
                            this.pageList()
                        }).catch(err=>{
                            this.$Message.error(err.msg)
                        })  
                    },
                });
            }
        },
    }
</script>

<style lang="scss" scoped>
.page{
    background-color: #F5F9FA;
    height: 100%;
}
.head{
    width: 100%;
    height: 70px;
    background-color: #fff;
    margin-bottom: 10px;
    div{
        float: left;
        margin: 10px 0 0 10px;
    }
}
.content{
    width: 100%;
    padding: 0 20px;
    border-radius: 10px;
    .Page{
        background-color: #fff;
        margin: 20px 0;
        text-align: right;
    }
}
.infoModal_text{
    width: 28px;
    height: 15px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #279EFB;
    line-height: 50px;
    margin: 20px;
    cursor: pointer;
}
</style>